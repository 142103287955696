/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col md="3">
              <h1 className="title">
                <strong>SETS General Trading P.L.C</strong>
              </h1>
            </Col>
            <Col md="3" xs="6">
              <Nav>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    <strong>Home</strong>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/Service-page" tag={Link}>
                    <strong>Service</strong>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/latestWork-page" tag={Link}>
                    <strong>Latest Work</strong>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/import-export" tag={Link}>
                    <strong>Import & Export</strong>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="3" xs="6">
              <Nav>
                <NavItem>
                  <NavLink to="/Contact-page" tag={Link}>
                    <strong>Contact Us</strong>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/AboutUs-page" tag={Link}>
                    <strong>About Us</strong>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/">
                    <strong>License</strong>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="3">
              <h3 className="title">
                <strong>Follow us:</strong>
              </h3>
              <div className="btn-wrapper profile text-left">
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="https://www.facebook.com/Sets-General-Trading-Plc-105034694476038/?ref=bookmarks"
                  id="tooltip230450801"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip230450801"
                >
                  Like us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="https://t.me/joinchat/AAAAAEt9A4ejGJfiLg4mMA"
                  id="tooltip230450801"
                  target="_blank"
                >
                  <i className="fab fa-telegram-plane" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip230450801"
                >
                  Connect with us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  id="tooltip318450378"
                  target="_blank"
                >
                  <i className="fab fa-whatsapp" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip318450378"
                >
                  Follow us
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
