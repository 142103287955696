import React from "react";
import ServiceHeader from "components/PageHeader/ServiceHeader.jsx"
import IndexNavbar from "components/Navbars/IndexNavbar.jsx"
import Footer from "components/Footer/Footer.jsx"
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

class Service extends React.Component {
  componentDidMount() {
    document.body.classList.add("latestWork-page");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }
  componentWillUnmount() {
    document.body.classList.remove("latestWork-page");
  }
  render() {
    return (
      <>
      <IndexNavbar />
      <ServiceHeader/>
      <img alt="..." className="path" src={require("assets/img/triunghiuri.png")} />
      <img alt="..." className="path" src={require("assets/img/waves.png")} />
      <br/> <br/> <br/><br/> <br/> <br/>
      <div
      className="section section-download"
      data-background-color="black"
      >
      <Container fluid id = "Service">
      <Row className="justify-content-md-center">
      <Col className="text-center" lg="8" md="12">
      <h1 className="title">
      Services
      </h1>
      <h3 className="text-warning">• • •</h3>
      <p className="description">
      SETS can Supply and installation of Aluminum fixtures 
      comprising Doors, Windows, Skylight-Dome, Handrail, Shutters, 
      Curtainwall, etc. including glazing, suspended ceiling made of 
      aluminum, soundproof and fireproof panels, suitable for Residential 
      Houses, Hotels, Hospitals, etc. Supply and installation of 
      re-locatable partitions with aluminum frames and a variety of 
      panels laminated with melamine, veneer, fire retardant, soundproof, 
      blind panels able to meet your various needs.
      </p>
      </Col>
      </Row> 
      </Container>
      </div>
      <br/><br/><br/><br/>
      <Container>
      <Row>
      <Col md="4">
      <div className="info info-hover">
      <div className="icon icon-primary">
      <i className="tim-icons icon-world" />
      </div>
      <h2 className="info-title">Import Export</h2>
      <h4 className="description">
      SETS Import Aluminium, Glass, Ceiling materials, Sanitary, 
      Electrical material and finishing materials from European Sources.
      </h4>
      </div>
      </Col>
      <Col md="4">
      <div className="info info-hover" style={{ marginTop: "-50px" }}>
      <div className="icon icon-warning">
      <i className="tim-icons icon-money-coins" />
      </div>
      <h2 className="info-title">Commission Agency</h2>
      <h4 className="description">
      SETS is the agent of Cimbria Heid who is supplying oil seeds and Coffee processing 
      machinery.
      </h4>
      </div>
      </Col>
      <Col md="4">
      <div className="info info-hover">
      <div className="icon icon-success">
      <i className="tim-icons icon-settings-gear-63" />
      </div>
      <h2 className="info-title">Manufacturing and Special Construction</h2>
      <p className="description">
      SETS manufactured aluminium frames for windows and doors, Sky light, dome, and partitions.
      </p>
      </div>
      </Col>
      </Row>
      </Container>
      <div className="cd-section" id="LatestWork">
      <div className="section blogs-2" id="blogs-2">
      <Container fluid>
      <Row>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Service1.jpg") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <h6 className="card-category">Sites</h6>
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">Mekonnen Bitew</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Service2.jpg") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <h6 className="card-category">Sites</h6>
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">
      Pluto Real State
      </CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/malawi1.png") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <h6 className="card-category">Sites</h6>
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">Embassy of Malawi</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Dr1.jpg") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <h6 className="card-category">Sites</h6>
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">DAT Building</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      </Row>
      </Container>
      <br/> <br/> <br/>
      <div
      className="section section-download"
      data-background-color="black"
      >
      <Container>
      <Row className="justify-content-md-center">
      <Col className="text-center" lg="8" md="12">
      <h1 className="title">
      Resources
      </h1>
      <h3 className="text-warning">• • •</h3>
      <p className="description">
      <strong>SETS operate in a facility comprising offices and workshop equipped 
      with the necessary machinery and tools to perform varied technical 
      activities with special emphasis on fabrication of aluminum fixtures and others.</strong>
      </p>
      </Col>
      </Row> 
      </Container>
      </div>
      <br/> <br/> <br/>
      <Container>
      <Row>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Resource1.JPG") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">Workshop</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Material1.JPG") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">
      Stores
      </CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Machinery1.JPG") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">Materials</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Machinery2.JPG") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">Machineries</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      </Row>    
      </Container>
      </div> 
      </div>
      <Footer/> {" "}
      </>
      );
    }
  }

  export default Service;
