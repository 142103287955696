/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.jsx"
import Footer from "components/Footer/Footer.jsx"
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle
} from "reactstrap";

class OtherBusiness extends React.Component {
 componentDidMount() {
    document.body.classList.add("latestWork-page");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }
  componentWillUnmount() {
    document.body.classList.remove("latestWork-page");
  }
    scrollPage = e => {
    e.preventDefault();
    document.getElementById("drfixit").scrollIntoView();
  };
   scrollPagetwo = e => {
    e.preventDefault();
    document.getElementById("tsehay").scrollIntoView();
  };
  render() {
    return (
      <>
      <IndexNavbar />
      <div className="cd-section" >
      <div className="header header-2">
      <div className="page-header ">
      <Container>
      <Row className="align-items-center text-center">
      <Col lg="6" xs="12">
      <h1 className="title">
      <strong>SETS Waterproofing</strong> 
      <br />
      <strong>General Trading P.L.C</strong>
      </h1>
      <h3 className="text-warning">• • •</h3>
      <p className="description">
      Sister Company of SETS General Trading Plc. Current operating 
      independently under license no. 14/673/53673/2004 and the company
      working on complete solutions of waterproofing & building repairs...
      </p>
      <Row className="row-input">
      <Col sm="4" xs="12">
      <Button
      className="btn-link"
      color="info"
      href="#pablo"
      onClick={this.scrollPage}
      >
      Read More{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </Col>
      </Row>
      </Col>
      <Col lg="6" xs="12">
      <h1 className="title ">
      <strong>Tsehay Feleke</strong> 
      <br />
      <strong>& Family Trading P.L.C</strong>
      </h1>
      <h3 className="text-warning">• • •</h3>
      <p className="description">
      A commercial private limited liable 
      company with operations and Investments 
      in Ethiopia. The company was established in 2011 and its
      currently registered to engage in the production and  export 
      of agricultural raw materials.
      </p>
      <Row className="row-input">
      <Col sm="4" xs="12">
       <Button
      className="btn-link"
      color="info"
      href="#pablo"
      onClick={this.scrollPagetwo}
      >
      Read More{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </Col>
      </Row>
      </Col>
      </Row>
      </Container>
      </div>
      </div>
      </div>
       <div
      className="section section-download"
      data-background-color="black"
      id = "tsehay"
      >
      <Container>
      <Row>
      <Col className="ml-auto mr-auto text-center" md="8">
      <h2 className="title"><strong>Welcome to Tsehay  Feleke</strong> <br/><strong>& Family
      Trading P.L.C</strong></h2>
      <h3 className="text-warning">• • •</h3>
      <h4 className="description">
      Tsehay Feleke & Family Trading is a commercial private limited liable 
      company with operations and investments in Ethiopia. The company was 
      established in 2001 and its currently registered and paid-up capital is 
      20 million Eth Birr so as to engage in the export of agricultural raw materials, 
      Seeds, Pulses, Oil Seeds, Spices and Cereals. <br/><br/>
      According to the business and property valuation report which has studied by Deloitte 
      Consulting Firm (international consultancy service) currently founders and shareholders 
      of Tsehay Feleke & Family Trading, W/o Zadigwa Tsehay Feleke and W/ro Semayate Tsehome 
      have more than 150 million Eth Birr invested capital including of other relating companies
       namely Sets and Sets -Water Proofing General Trading companies. These two companies are
        mainly operating in construction services.

      </h4>
      </Col>
      </Row>
      </Container>
      </div>
      <Container>
      <Row>
      <Col className="ml-auto mr-auto text-center" md="8">
      <h2 className="title"><strong>Product and Service</strong></h2>
      <h3 className="text-warning">• • •</h3>
      <h4 className="description">
      <strong>Trade and Export of agricultural raw materials, 
      cereals, oil seeds, pulses, pepper and spices.</strong>
      </h4>
      </Col>
      </Row>
      <Row>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Turmeric.jpg") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">Turmeric</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Soya_Beans.jpg") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">
      Soya Beans
      </CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Niger_Seeds.jpg") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">Niger Seeds</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      <Col lg="3">
      <Card
      className="card-blog card-background"
      data-animation="zooming"
      >
      <div
      className="full-background"
      style={{
        backgroundImage:
        "url(" + require("assets/img/White_Pea_Beans_and_Sesame.jpg") + ")"
      }}
      />
      <CardBody>
      <div className="content-bottom">
      <a href="#pablo" onClick={e => e.preventDefault()}>
      <CardTitle tag="h3">White Pea Beans and Sesame</CardTitle>
      </a>
      </div>
      </CardBody>
      </Card>
      </Col>
      </Row>    
      </Container>
      
      <div className="section" id = "drfixit">
       <div
      className="section section-download"
      data-background-color="black"
      >
      <Container>
      <Row>
      <Col className="ml-auto mr-auto text-center" md="8">
      <h2 className="title"><strong>Welcome To SETS Waterproofing</strong> 
      <br />
      <strong>General Trading P.L.C</strong></h2>
      <h3 className="text-warning">• • •</h3>
      <h4 className="description">
      Supply and installation of waterproofing materials for roof treatment, 
      foundations, water containers made of concrete etc. Supply special adhesives 
      for application of ceramic products natural stone and granite for wall and floor. 
        We have also special cement based sealant in various colors for ceramic tile joint 
      grouting. We can be your partner in the provision of complete technical specification 
      and supply of finishing materials for modern swimming pool.
      </h4>
      </Col>
      </Row>
      </Container>
      </div>
      <div className="team-1">
      <br/>
      <br/>
      <Container fluid>
      <Row>
      <Col className="mr-auto ml-auto text-center" md="6">
      <h1 className="title"><strong>Our Products</strong></h1>
      <h3 className="text-warning">• • •</h3>
      <h4 className="description">
      </h4>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Pidiproof LW+</h3>
      <p>
      Integral liquid waterproofing compound for concrete & plaster to reduce dampness.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Super Latex</h3>
      <p>
      SBR Latex for waterproofing & repairs.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-danger">
      <i className="tim-icons icon-chart-bar-32" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Pidifin 2K</h3>
      <p>
      Acrylic cementitious brush applied waterproof coating for bathrooms, 
      kitchen & small roofs.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-info">
      <i className="tim-icons icon-paper" />
      </div>
      <div className="description">
      <h3 className="info-title">
      Dr. Fixit Fastflex{" "}
      </h3>
      <p>
      High performance polymer modified cementitious membrane for swimming pools.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>


      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Krystalline</h3>
      <p>
      Cementitious crystalline waterproofing coating for concrete.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Newcoat Ezee</h3>
      <p>
      Fiber Reinforced Acrylic Waterproof Coating for Roofs and Terraces.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Powder Waterproof</h3>
      <p>
      Integral powder waterproofing compound for plaster & concrete.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Pidicrete URP</h3>
      <p>
      SBR Latex for waterproofing & repairs.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Raincoat</h3>
      <p>
      Acrylic elastomeric exterior waterproof decorative coating for exterior walls.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Primeseal</h3>
      <p>
      Efflorescence resistant penetrating primer.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Roofseal</h3>
      <p>
      Cold applied elastomeric bituminous water proof coating.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Pidibit Emulsion</h3>
      <p>
      Single component polymer modified bituminous coating.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Torchshield</h3>
      <p>
      APP / SBS modified bitumen based membranes for water proofing of roofs & basements.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Dampguard</h3>
      <p>
      Damp–proof epoxy coating for treating internal dampness on internal walls & ceilings.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Dampfree</h3>
      <p>
      Siliconate based injection grout for rising dampness.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Waterbar</h3>
      <p>
      Flexible adhesive strip water stop.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit PVC Waterbar AE</h3>
      <p>
      PVC water for joints in concrete structures.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Crack-X Powder</h3>
      <p>
      A non-shrink, high strength powder crack filler for surface cracks in plaster.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Crack – X Shrink free</h3>
      <p>
      100% shrink – free crack filler for cracks up to 10 mm.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Instant Leak Plug</h3>
      <p>
      Fast setting powder for sealing of drip leakages.
      </p>
      <Button
     className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Pidiseal PS 41 G/42 P</h3>
      <p>
      Two-component polysulphide sealant.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Silicone Sealant</h3>
      <p>
      Acetic cure silicone sealant for windows.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Gapseal Tape</h3>
      <p>
      Self-adhesive sealing tape.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">US PRO FOAM</h3>
      <p>
      Multipurpose polyurethane foam.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      <Row>
      <Col className="ml-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-success">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit Bathseal Kit</h3>
      <p>
      One-stop solution for 100% Leak-Free Bathrooms.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>

      <Col className="mr-auto" md="5">
      <div className="info info-horizontal">
      <div className="icon icon-warning">
      <i className="fab fa-product-hunt" />
      </div>
      <div className="description">
      <h3 className="info-title">Dr. Fixit UniTile / Fevimate XL</h3>
      <p>
      Ceramic Tile Adhesive.
      </p>
      <Button
      className="btn-link"
      color="info"
      target="_blank"
      href="https://www.ethioconstruction.net/?q=contact-address/sets-waterproofing-general-trading-plc"
      >
      Explore now{" "}
      <i className="tim-icons icon-minimal-right" />
      </Button>
      </div>
      </div>
      </Col>
      </Row>

      </Container>
      </div>
      </div>
      <br/><br/>
      <Footer/>
      </>
      );
    }
  }

  export default OtherBusiness;
