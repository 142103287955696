import React from "react";
// nodejs library that concatenates classes
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx"

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

class ContactUs extends React.Component {
  componentDidMount() {
    document.body.classList.add("Contact-page");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }
  componentWillUnmount() {
    document.body.classList.remove("Contact-page");
  }
  state = {};
  render() {
    return (
      <>
      <IndexNavbar />
      <div className="cd-section" >
      <div className="header header-2">
      <div className="page-header ">

      <div
      className="section section-download"
      data-background-color="black"
      >
      <Container>
      <Row className="align-items-center text-center">
      <Col lg="6" xs="12">
      <h1 className="title ">
      <strong >Contact Us</strong>
      </h1>
      <h3 className="text-warning">• • •</h3>
      <p className="description">
      Many thanks for making the right choice to contact us. 
      Our primary goal is to make our service available to you best 
      quality at a very reasonable price. Please keep in touch at the 
      following address and you will be pleased for doing it.
      </p>
      </Col>
      <Col lg="6">
      <div className="featured-image">
      <img
      alt="..."
      height="450"
      src={require("assets/img/Contact.jpg")}
      width="450"
      />
      </div>
      </Col>
      </Row>
      </Container>
      </div>
      </div></div></div>{" "}
      <div
      className="section section-download"
      data-background-color="black"
      id = "tsehay-contact"
      >
      <Container>
      <Row>
      <Col className="ml-auto mr-auto text-center" md="8">
      <h2 className="title"><strong>SETS</strong>  <br/><strong>General Trading P.L.C</strong> </h2>
      <h3 className="text-warning">• • •</h3>
      </Col>
      </Row>
      </Container>
      </div>
      <div className="contactus-3">
      <Container fluid>
      <Row className="infos mb-5">
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-primary">
      <i className="tim-icons icon-square-pin" />
      </div>
      <h4 className="info-title">Address</h4>
      <p className="description"> Jackros Street, <br />
      Addis Abeba, Ethiopia</p>
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-info">
      <i className="tim-icons icon-email-85" />
      </div>
      <h4 className="info-title">Email</h4>
      <p className="description">sets@ethionet.et <br />
      mamesets@gmail.com<br /></p>
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-warning">
      <i className="tim-icons icon-mobile" />
      </div>
      <h4 className="info-title">Phone Number</h4>
      <p className="description">+251-11-646-17-36/37<br />
      +251-11-645-98-17/18<br />
      <a href="tel:+251-911-20-73-30">+251-911-20-73-30</a><br />
      <a href="tel:+251-911-20-89-78">+251-911-20-89-78</a><br />
      FAX. 251-11-646-34-83</p>
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-success">
      <i className="tim-icons icon-user-run" />
      </div>
      <h4 className="info-title">Working Hour</h4>
      <p className="description">Monday to Friday: <br />
      From 8 am to 5 pm <br />
      Saturday: <br />From 8 am to 12:30 </p>
      </div>
      </Col>
      </Row>
      </Container>
      </div>
      <div
      className="section section-download"
      data-background-color="black"
      id = "tsehay-contact"
      >
      <Container>
      <Row>
      <Col className="ml-auto mr-auto text-center" md="8">
      <h2 className="title"><strong>Tsehay  Feleke</strong> <br/><strong>& Family 
      Trading P.L.C</strong> </h2>
      <h3 className="text-warning">• • •</h3>
      </Col>
      </Row>
      </Container>
      </div>
      <div className="contactus-3">
      <Container fluid>
      <Row className="infos mb-5">
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-primary">
      <i className="tim-icons icon-square-pin" />
      </div>
      <h4 className="info-title">Address</h4>
      <p className="description"> 
      Oromia region, Misrak Shoa Zone, 
      Ada Woreda, Wajitu Debdebe Kebele and located 40 kilometers 
      distance from the capital city of Ethiopia - DUKEM
      </p>
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-info">
      <i className="tim-icons icon-email-85" />
      </div>
      <h4 className="info-title">Email</h4>
      <p className="description">sets@ethionet.et</p>
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-warning">
      <i className="tim-icons icon-mobile" />
      </div>
      <h4 className="info-title">Phone Number</h4>
      <a href="tel:+251-930-04-39-95">+251-930-043995</a><br />
      <a href="tel:+251-911-20-73-30">+251-911-207330</a><br />
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-success">
      <i className="tim-icons icon-single-02" />
      </div>
      <h4 className="info-title">Contact</h4>
      <p className="description">MICHAEL COSTA <br />
      ZADIGWA TSEHAY  <br />
      </p>
      </div>
      </Col>
      </Row>
      </Container>
      </div>
        <div
      className="section section-download"
      data-background-color="black"
      id = "tsehay-contact"
      >
      <Container>
      <Row>
      <Col className="ml-auto mr-auto text-center" md="8">
      <h2 className="title"><strong>SETS Waterproffing</strong> <br/>
       <strong>General Trading P.L.C</strong> </h2>
      <h3 className="text-warning">• • •</h3>
      </Col>
      </Row>
      </Container>
      </div>
      <div className="contactus-3">
      <Container fluid>
      <Row className="infos mb-5">
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-primary">
      <i className="tim-icons icon-square-pin" />
      </div>
      <h4 className="info-title">Address</h4>
      <p className="description"> 
      Bole Subcity <br/> Wereda 07 <br/>
      P.O.BOX 8191<br/>
      Addis Ababa, Ethiopia
      </p>
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-info">
      <i className="tim-icons icon-email-85" />
      </div>
      <h4 className="info-title">Email</h4>
      <p className="description">titisets@gmail.com</p>
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-warning">
      <i className="tim-icons icon-mobile" />
      </div>
      <h4 className="info-title">Phone Number</h4>
      <p className="description">0116-46-37-36</p>
      <p className="description">0116-46-41-67</p>
      <a href="tel:+251-911-20-89-78">+251-911-208978</a><br />
      </div>
      </Col>
      <Col lg="3">
      <div className="info info-hover">
      <div className="icon icon-success">
      <i className="tim-icons icon-user-run" />
      </div>
      <h4 className="info-title">Working Hour</h4>
      <p className="description">Monday to Friday: <br />
      From 8 am to 5 pm <br />
      Saturday: <br />From 8 am to 12:30 </p>
      </div>
      </Col>
      </Row>
      </Container>
      </div>
      <Footer/>
      </>
      );
    }
  }

  export default ContactUs;


