/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {Container, Row, Col} from "reactstrap";

class welcomePage extends React.Component {
  render() {
    return (
      <div
        className="section section-download"
        data-background-color="black"
      >
        
        <img alt="..." className="path" src={require("assets/img/patrat.png")} />
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h1 className="title">
                <strong>Welcome To SETS Ethiopia</strong>
              </h1>
              <h3 className="text-warning">• • •</h3>
              <p className="description">
                SETS company works as a family business. 
                It was established in 1986 with an energetic 
                father Ato Tsehay Feleke and Mother W/o Semayate 
                Teshome. SETS work in Import, Export, Commission 
                agent and Manufacturing of aluminum frames with 
                Special contractors. SETS operate in a facility 
                comprising offices and workshop equipped with the 
                necessary machinery and tools to perform varied 
                technical activities with special emphasis on the 
                fabrication of aluminum fixtures and others. SETS 
                has established reliable contact with manufacturers 
                and suppliers based in Italy, Belgium, Denmark, and 
                Austria. The manpower strength of SETS is capable of 
                handling big projects making it highly competitive and 
                reliable in the provision of engineering services.
              </p>
              <video 
              //align="center"
              width="900"
              //height="1080"
              controls>
                
  <source src={require("assets/img/sets.mp4") } type="video/mp4"/>

</video>
            </Col>
          </Row>     
        </Container>
      </div>
    );
  }
}

export default welcomePage;
