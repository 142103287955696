import React from "react";
import Slick from "react-slick";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
let slickHeader2Settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  arrows: false,
  dots: false,
  pauseOnHover: false,
  className: "customer-logos",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
class ServiceHeader extends React.Component {
   scrollPage = e => {
    e.preventDefault();
    document.getElementById("Service").scrollIntoView();
  };
  render() {
    return (
      <>
        <div className="cd-section" >
          <div className="header header-2">
            <div className="page-header header-filter">
              <div
                className="page-header-image"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/Service4.jpg") + ")"
                }}
              />
              <Container>
                <Row>
                  <Col className="mr-auto text-left mt-5" lg="5" md="7">
                    <h1 className="title">
                    <strong>Our Service</strong>
                    </h1>
                    <h3 className="text-warning">• • •</h3>
                    <p className="description">
                      SETS can Supply and installation of Aluminum fixtures comprising Doors, 
                      Windows, Skylight-Dome, Handrail, Shutters, Curtain wall etc. including 
                      glazing, suspended ceiling made of aluminum....
                    </p>
                    <br/>
                    <div className="buttons">
                      <Button
                        className="btn-round mr-3 pulse"
                        color="primary"
                        href="#pablo"
                        size="lg"
                        onClick={this.scrollPage}
                      >
                        <i className="tim-icons icon-delivery-fast" />  
                      </Button>
                      <strong>Explore Now!</strong>
                    </div>
                  </Col>
                </Row>
                <br/><br/>
                <Row>
                  <Slick {...slickHeader2Settings}>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/Logo.jpg")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/cimbria.png")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/Ase.png")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/acomx.png")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/Empire.jpg")}
                      />
                    </div>
                  </Slick>
                </Row>
              </Container>
            </div>
          </div>
        </div>{" "}
      </>
    );
  }
}

export default ServiceHeader;
