/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

class LatestWorkHeader extends React.Component {
  constructor(props) {
    super(props);
    let windowScrollTop;
    if (window.innerWidth >= 768) {
      windowScrollTop = window.pageYOffset / 3;
    } else {
      windowScrollTop = 0;
    }
    this.state = {
      transform: "translate3d(0," + windowScrollTop + "px,0)",
      carousel1Index: 0
    };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("ecommerce-page");
    if (window.innerWidth >= 768) {
      var windowScrollTop = window.pageYOffset / 3;
      this.setState({
        transform: "translate3d(0," + windowScrollTop + "px,0)"
      });
      window.addEventListener("scroll", this.resetTransform);
    }
  }
  componentWillUnmount() {  
    document.body.classList.remove("ecommerce-page");
    if (window.innerWidth >= 768) {
      window.removeEventListener("scroll", this.resetTransform);
    }
  }
  resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: "translate3d(0," + windowScrollTop + "px,0)"
    });
  };
  render() {
    return (
      <>
      <div className="wrapper" ref="wrapper">
      <div className="header header-1">
      <div className="page-header header-filter">
      <div
      className="page-header-image"
      style={{
        backgroundImage:
        "url(" + require("assets/img/Set4.jpg") + ")",
        transform: this.state.transform
      }}
      />

      <Container>
      <Row>
      <Col className="mr-auto text-left" lg="6" md="7">
      <h1 className="title"><strong>Our Latest Work</strong></h1>
      </Col>
      </Row>
      </Container>
      </div>
      </div>
      </div>
      </>
      );
    }
  }

  export default LatestWorkHeader;

