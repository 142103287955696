import React from "react";
import Slick from "react-slick";

// reactstrap components
import {
  Container,
  Row
} from "reactstrap";
let slickHeader2Settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  arrows: false,
  dots: false,
  pauseOnHover: false,
  className: "customer-logos",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
class ServiceHeader extends React.Component {
   scrollPage = e => {
    e.preventDefault();
    document.getElementById("Service").scrollIntoView();
  };
  render() {
    return (
      <>
        <div className="cd-section" >
          <div className="header header-2">
              <Container>
                <br/><br/>
                <Row>
                  <Slick {...slickHeader2Settings}>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/BankBuna.jpg")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/BankZemen.jpg")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/BankCoop.png")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/BankCBE.jpg")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/BankUB.jpg")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/BankAB.png")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/BankEnat.png")}
                      />
                    </div>
                    <div className="slide">
                      <img
                        alt="..."
                        src={require("assets/img/BankAwash.png")}
                      />
                    </div>
                  </Slick>
                </Row>
              </Container>
            </div>
        </div>{" "}
      </>
    );
  }
}

export default ServiceHeader;
