/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import 'assets/css/nucleo-icons.css';
/*import "assets/css/blk-style.css";*/
import 'assets/css/blk-design-system-pro.css';
import 'jquery/dist/jquery.min.js';

import Index from 'views/Index.jsx';
import LatestWork from 'views/Components/LatestWork.jsx';
import ContactUS from 'views/Components/ContactUs.jsx';
import Service from 'views/Components/Service.jsx';
import OtherBusiness from 'views/Components/OtherBusiness.jsx';
import AboutUs from 'views/Components/AboutUs.jsx';
import ImportExport from './views/Components/ImportExport';

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/Home" render={(props) => <Index {...props} />} />
      <Route
        path="/latestWork-page"
        render={(props) => <LatestWork {...props} />}
      />
      <Route
        path="/import-export"
        render={(props) => <ImportExport {...props} />}
      />
      <Route
        path="/Contact-page"
        render={(props) => <ContactUS {...props} />}
      />
      <Route
        path="/Service-page"
        render={(props) => <Service {...props} />}
      />
      <Route
        path="/Business-page"
        render={(props) => <OtherBusiness {...props} />}
      />
      <Route
        path="/AboutUs-page"
        render={(props) => <AboutUs {...props} />}
      />
      <Redirect from="/" to="/Home" />
    </Switch>
  </HashRouter>,
  document.getElementById('root')
);
