/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
import Headroom from 'headroom.js';
import pdf from 'assets/file/Certificate.pdf';
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

class ComponentsNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent',
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.changeColor);
    let headroom = new Headroom(
      document.getElementById('navbar-scroll')
    );
    // initialise
    headroom.init();
  }
  scrollPage = (e, id) => {
    e.preventDefault();
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView();
    }
  };
  componentWillUnmount() {
    window.removeEventListener('scroll', this.changeColor);
  }
  changeColor = () => {
    if (
      document.documentElement.scrollTop > 299 ||
      document.body.scrollTop > 299
    ) {
      this.setState({
        color: 'bg-white',
      });
    } else if (
      document.documentElement.scrollTop < 300 ||
      document.body.scrollTop < 300
    ) {
      this.setState({
        color: 'navbar-transparent',
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle('nav-open');
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: 'collapsing-out',
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: '',
    });
  };
  render() {
    return (
      <Navbar
        className={'fixed-top ' + this.state.color}
        color-on-scroll="100"
        expand="lg"
        id="navbar-scroll"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              to="/"
              rel="noopener noreferrer"
              title="Powered by SETS Ethiopia"
              tag={Link}
            >
              <img src={require('assets/img/Set.png')} alt=""></img>
            </NavbarBrand>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={
              'justify-content-end ' + this.state.collapseOut
            }
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    SETS General Trading P.L.C
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  to="/"
                  tag={Link}
                  rel="noopener noreferrer"
                  title="Back to Home"
                >
                  <strong>Home</strong>
                </NavLink>
              </NavItem>

              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  to="Service-page"
                  tag={Link}
                  rel="noopener noreferrer"
                  title="Show our service"
                >
                  <strong>Service</strong>
                </NavLink>
              </NavItem>

              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  to="latestWork-page"
                  tag={Link}
                  rel="noopener noreferrer"
                  title="Show our Latest Work"
                >
                  <strong>Latest Work</strong>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="import-export" tag={Link}>
                  <strong>Import & Export</strong>
                </NavLink>
              </NavItem>

              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  to="Business-page"
                  tag={Link}
                  rel="noopener noreferrer"
                  title="Show Other work"
                >
                  <strong>Other Work</strong>
                </NavLink>
              </NavItem>
              <NavItem className="p-0">
                <NavLink
                  data-placement="bottom"
                  to="AboutUs-page"
                  tag={Link}
                  rel="noopener noreferrer"
                  title="Show About Us"
                >
                  <strong>About Us</strong>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fa fa-cogs d-lg-none d-xl-none" />
                  <strong>Company</strong>
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  <DropdownItem href={pdf} target="_blank">
                    <i className="tim-icons icon-bullet-list-67" />
                    <strong>Testimonials</strong>
                  </DropdownItem>
                  <DropdownItem tag={Link} to="Contact-page">
                    <i className="tim-icons icon-single-02" />
                    <strong>Contact US</strong>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default ComponentsNavbar;
