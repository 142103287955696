import React from 'react';
import IndexNavbar from 'components/Navbars/IndexNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';

// reactstrap components
import {
  Container,
  Row,
  Col,
  CardBody,
  CardTitle,
  Card,
} from 'reactstrap';
// core components
class ImportExport extends React.Component {
  onExiting = (carousel) => {
    this['carousel' + carousel + 'Animating'] = true;
  };

  onExited = (carousel) => {
    this['carousel' + carousel + 'Animating'] = false;
  };
  next = (carousel, items) => {
    if (this['carousel' + carousel + 'Animating']) return;
    const nextIndex =
      this.state['carousel' + carousel + 'Index'] === items.length - 1
        ? 0
        : this.state['carousel' + carousel + 'Index'] + 1;
    this.setState({ ['carousel' + carousel + 'Index']: nextIndex });
  };
  previous = (carousel, items) => {
    if (this['carousel' + carousel + 'Animating']) return;
    const nextIndex =
      this.state['carousel' + carousel + 'Index'] === 0
        ? items.length - 1
        : this.state['carousel' + carousel + 'Index'] - 1;
    this.setState({ ['carousel' + carousel + 'Index']: nextIndex });
  };
  render() {
    return (
      <>
        <IndexNavbar />
        {/* <ImportExportHeader /> */}
        <div
          className="section section-download"
          data-background-color="black"
        >
          <div className="section">
            <div className="team-1">
              <img
                alt="..."
                className="path"
                src={require('assets/img/triunghiuri.png')}
              />
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">
                      <strong>Our Export</strong>
                    </h2>
                    <h3 className="text-warning">• • •</h3>
                    <h4 className="description">
                      <strong>
                        Trade and Export of agricultural raw
                        materials, cereals, oil seeds, pulses, pepper
                        and spices.
                      </strong>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/Turmeric.jpg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Turmeric Finger
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/TurmericPowder.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Turmeric Powder
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/RedKidneyBeans.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle tag="h3">
                              <div style={{ color: 'white' }}>
                                Red Kidney Beans
                              </div>
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/White_Pea_Beans_and_Sesame.jpg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              White Pea Beans
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/harvested-sesame-seeds.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Sesame Seeds
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/MaizePowder.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Maize Powder
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/MungBeans.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Green Mung Beans
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/Soya_Beans.jpg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Soya Beans
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="ml-auto mr-auto text-center mt-5"
                    md="8"
                  >
                    <h2 className="title">
                      <strong>Our Import</strong>
                    </h2>
                    <h3 className="text-warning">• • •</h3>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/curtain_walls.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Curtain Walls
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/handrail.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Handrail
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/security-glass.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle tag="h3">
                              <div style={{ color: 'white' }}>
                                Security Glass
                              </div>
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3">
                    <Card
                      className="card-blog card-background"
                      data-animation="zooming"
                    >
                      <div
                        className="full-background"
                        style={{
                          backgroundImage:
                            'url(' +
                            require('assets/img/Frosted-glass.jpeg') +
                            ')',
                        }}
                      />
                      <CardBody>
                        <div className="content-bottom">
                          <a
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <CardTitle
                              tag="h3"
                              style={{ color: 'white' }}
                            >
                              Frosted Glass
                            </CardTitle>
                          </a>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className="align-items-center text-center ">
                  <Col lg="6" xs="12">
                    <h1 className="title">
                      <strong>Alumunium</strong>
                      <br />
                    </h1>
                    <h3 className="text-warning">• • •</h3>
                    <p className="description">
                      Window and Door · Curtain Wall · Hand Rail · Sky
                      Light · Dome · Partition · Cladding ·
                      Sun-Breaker · Security Door · Aluminum
                      Accessories and more.
                    </p>
                  </Col>
                  <Col lg="6" xs="12">
                    <h1 className="title ">
                      <strong>Glass</strong>
                    </h1>
                    <h3 className="text-warning">• • •</h3>
                    <p className="description">
                      Clear · Security · Reflective · Laminated ·
                      Frosted · Tinted · Floated and more.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">
                      <strong>Contact Us</strong>{' '}
                    </h2>
                    <h3 className="text-warning">• • •</h3>
                  </Col>
                </Row>

                <div className="contactus-3">
                  <Container fluid>
                    <Row className="infos mb-5">
                      <Col lg="3">
                        <div className="info info-hover">
                          <div className="icon icon-primary">
                            <i className="tim-icons icon-square-pin" />
                          </div>
                          <h4 className="info-title">Address</h4>
                          <p className="description">
                            {' '}
                            Jackros Street, <br />
                            Addis Abeba, Ethiopia
                          </p>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="info info-hover">
                          <div className="icon icon-info">
                            <i className="tim-icons icon-email-85" />
                          </div>
                          <h4 className="info-title">Email</h4>
                          <p className="description">
                            titisets@gmail.com <br />
                            mamesets@gmail.com
                            <br />
                          </p>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="info info-hover">
                          <div className="icon icon-warning">
                            <i className="tim-icons icon-mobile" />
                          </div>
                          <h4 className="info-title">Phone Number</h4>
                          <a href="tel:+251-911-20-89-78">
                            +251-911-20-89-78
                          </a>
                          <br />
                          <a href="tel:+251-911-20-73-30">
                            +251-911-20-73-30
                          </a>
                          <br />
                          <p className="description">
                            +251-11-645-98-17/18
                            <br />
                            FAX. 251-11-646-34-83
                          </p>
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="info info-hover">
                          <div className="icon icon-success">
                            <i className="tim-icons icon-user-run" />
                          </div>
                          <h4 className="info-title">Working Hour</h4>
                          <p className="description">
                            Monday to Friday: <br />
                            From 8 am to 5 pm <br />
                            Saturday: <br />
                            From 8 am to 12:30{' '}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Container>
            </div>
          </div>
        </div>
        <Footer />{' '}
      </>
    );
  }
}

export default ImportExport;
