/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import {Button,Container} from "reactstrap";
class PageHeader extends React.Component {
  render() {
    return (
     <>
     <div className="header header-4">
     <div className="header-wrapper">
     <div className="page-header header-filter">
     <div class ="page-header-image">
     {/*<UncontrolledCarousel items={carouselItems} />*/}
     <div
        className="page-header-image"
        style={{
          backgroundImage:
            "url(" + require("assets/img/cart1.jpg") + ")"
        }}
      />
     </div>
     <Container className="text-center">
     <div class="content-center brand">
     <br/><br/><br/><br/> 
     <h1 class="h1-seo ">SETS <br/>General Trading P.L.C </h1>
     <h3 class="text-danger"><strong>(A.COM.EX-SETS)</strong></h3>
      <h3 className="text-warning">• • •</h3>
     <h3 >A Winning Synergy</h3>
     <Button
     className="btn-simple mt-4"
     color="warning"
     to = "Service-page"
     tag = {Link}
     size="lg"
     >
     Our Service
     </Button>
     </div>
     </Container>
     </div>
     </div>
     </div>
     </>

/*<div class = "page-header-image">
          <UncontrolledCarousel items={carouselItems} />
        </div>
       <div class ="page-header header-filter">
        
       <div class="container">
        <div class="content-center brand">
        <br/><br/><br/><br/> <br/><br/><br/><br/>
          <h1 class="h1-seo">A.COM.EX. <small class="text-warning">Srl</small></h1>
          <h3>Company Profile.</h3>
          <Button
                className="btn-simple mt-4"
                color="warning"
                to = "Service-page"
                tag = {Link}
                size="lg"
                
              >
                Our Service
              </Button>
          </div>
          </div>
          </div>*/



/*
      <div >
        <UncontrolledCarousel
          items={carouselItems}
          indicators={true}
        />
          <div className="content-center brand">
            <h1 className="h1-seo">Acomex <small class = "text-warning">Sets</small> </h1>
            <h3>
              Company Profile 
            </h3>
            <Button className="btn-round" color="primary" type="button" to = "service-page" tag = {Link}>
                Check out our service 
            </Button>
          </div>
          </div>*/
          );
        }
      }

      export default PageHeader;
