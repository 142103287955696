import React from "react";
import LatestWorkHeader from "components/PageHeader/LatestWorkHeader.jsx"
import IndexNavbar from "components/Navbars/IndexNavbar.jsx"
import Footer from "components/Footer/Footer.jsx"
import Testimonials from "views/Components/Testimonials.jsx"
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
class LatestWork extends React.Component {
  
  onExiting = carousel => {
    this["carousel" + carousel + "Animating"] = true;
  };

  onExited = carousel => {
    this["carousel" + carousel + "Animating"] = false;
  };
  next = (carousel, items) => {
    if (this["carousel" + carousel + "Animating"]) return;
    const nextIndex =
    this.state["carousel" + carousel + "Index"] === items.length - 1
    ? 0
    : this.state["carousel" + carousel + "Index"] + 1;
    this.setState({ ["carousel" + carousel + "Index"]: nextIndex });
  };
  previous = (carousel, items) => {
    if (this["carousel" + carousel + "Animating"]) return;
    const nextIndex =
    this.state["carousel" + carousel + "Index"] === 0
    ? items.length - 1
    : this.state["carousel" + carousel + "Index"] - 1;
    this.setState({ ["carousel" + carousel + "Index"]: nextIndex });
  };
  render() {
    return (
      <>
      <IndexNavbar />
      <LatestWorkHeader/>
       <div
      className="section section-download"
      data-background-color="black"
      >
      <div className="section">
      <div className="team-1">
      <img alt="..." className="path" src={require("assets/img/triunghiuri.png")} />
      <Container>
      <Row>
      <Col className="ml-auto mr-auto text-center" md="8">
      <h2 className="title"><strong>Our Latest Work</strong></h2>
       <h3 className="text-warning">• • •</h3>
      <h4 className="description">
      Since our establishment, we have been successful in Supply and installation of 
      Aluminum fixtures comprising Doors, Windows, Skylight-Dome, Handrail, Shutters, Curtain wall.
      We work on different Real estate house and apartments.These project examples will demonstrate why 
      customers choose Sets.
      </h4>
      </Col>
      </Row>
      </Container>
      <Testimonials/>
      </div>
      </div>
      </div>
       <Footer/> {" "}
      </>
      );
    }
  }

  export default LatestWork;    