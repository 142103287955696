/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// ReactJS plugin for a nice carousel

// reactstrap components
import {
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
} from "reactstrap";

// core components
const items1 = [
{
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Rayners</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              This project done in Meskel Flower 
              area with Raynner best quality of aluminium 
              and sound proof glass from Belgium.
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/Rayners.jpg")}
            />
          </Col>
        </Row>
      </Container>
      
    ),
    altText: "",
    caption: "",
    src: "1"
  },
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Real Estate <br/> House</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              SETS is friendly with Real estate 
              house and apartments understand 
              the living of Habitants. We Supply
              and install Aluminium fixtures 
              comprising Doors, Windows, 
              Skylight-Dome, Handrail, 
              Shutters, Curtain wall etc.
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/LatestworkC.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },
  
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Embassy of <br/> Malawi</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              Located in Bole area with Nice cladding work, aluminium frames..
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/malawi2.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">DAT Building</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              Located in Bole area having Ground +19 floors with offices and shops...
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/DrTena.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Shekina Bulding</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              Located in Goro area. Total 8
              apartments having each apratments. 
              Complete with aluminium frames, handrail 
              and cladding too...
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/Shekhina2.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Shipping <br/> Lines</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
            
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/pic1.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Nile  <br/> Insurance</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
            
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/nile.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },

  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">TNT<br/> </CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
            located at arbaminch  
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/tnt.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },
  {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Debre <br/> Ephrata</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">Located at jimma
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/debre ephrata jimma.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },
  
   {
    content: (
      <Container>
        <Row>
          <Col className="mr-auto" md="5">
            <div className="space-100" />
            <CardTitle tag="h3">Afework Building</CardTitle>
            <h3 className="text-warning">• • •</h3>
            <h4 className="description">
              Located in Bambis area. It is a private building having different offices and shops...
            </h4>
          </Col>
          <Col className="ml-auto" md="6">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/Affewerk2.jpg")}
            />
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0"
  },
  {
   content: (
     <Container>
       <Row>
         <Col className="mr-auto" md="5">
           <div className="space-100" />
           <CardTitle tag="h3">Tsadkane hensa </CardTitle>
           <h3 className="text-warning">• • •</h3>
           <h4 className="description">
             
           </h4>
         </Col>
         <Col className="ml-auto" md="6">
           <img
             alt="..."
             className="d-block"
             src={require("assets/img/20.jpg")}
           />
         </Col>
       </Row>
     </Container>
   ),
   altText: "",
   caption: "",
   src: "0"
 },
 {
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3"> Tsadkane Maryam </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/sadkane maryam.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">Flipper International School</CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic2.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">OMTHA </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic3.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">Prosperity Party </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/21.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">SACUR </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic5.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">Intrtnational Tennise Club </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic7.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">ALSAM </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic8.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">Ethio Canada </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic9.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">Resdential</CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            located around CMC
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic11.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">PUTO </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic12.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},

{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">Sound proof partion  </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic14.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},
{
  content: (
    <Container>
      <Row>
        <Col className="mr-auto" md="5">
          <div className="space-100" />
          <CardTitle tag="h3">Grill </CardTitle>
          <h3 className="text-warning">• • •</h3>
          <h4 className="description">
            
          </h4>
        </Col>
        <Col className="ml-auto" md="6">
          <img
            alt="..."
            className="d-block"
            src={require("assets/img/pic15.jpg")}
          />
        </Col>
      </Row>
    </Container>
  ),
  altText: "",
  caption: "",
  src: "0"
},







];

class Testimonials extends React.Component {
  state = {
    carousel1Index: 0,
    carousel2Index: 0
  };
  onExiting = carousel => {
    this["carousel" + carousel + "Animating"] = true;
  };

  onExited = carousel => {
    this["carousel" + carousel + "Animating"] = false;
  };
  next = (carousel, items) => {
    if (this["carousel" + carousel + "Animating"]) return;
    const nextIndex =
      this.state["carousel" + carousel + "Index"] === items.length - 1
        ? 0
        : this.state["carousel" + carousel + "Index"] + 1;
    this.setState({ ["carousel" + carousel + "Index"]: nextIndex });
  };
  previous = (carousel, items) => {
    if (this["carousel" + carousel + "Animating"]) return;
    const nextIndex =
      this.state["carousel" + carousel + "Index"] === 0
        ? items.length - 1
        : this.state["carousel" + carousel + "Index"] - 1;
    this.setState({ ["carousel" + carousel + "Index"]: nextIndex });
  };
  goToIndex = (newIndex, carousel) => {
    if (this["carousel" + carousel + "Animating"]) return;
    this.setState({ ["carousel" + carousel + "Index"]: newIndex });
  };
  render() {
    return (
      <>
        <div className="cd-section" id="testimonials">
          <div className="testimonials-1">
            <Container>
              <Row>
              </Row>
              <Carousel
                activeIndex={this.state.carousel1Index}
                next={() => this.next(1, items1)}
                previous={() => this.previous(1, items1)}
                className="carousel-team"
              >
                {items1.map((item, key) => {
                  return (
                    <CarouselItem
                      onExiting={() => this.onExiting(1)}
                      onExited={() => this.onExited(1)}
                      key={key}
                    >
                      {item.content}
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    this.previous(1, items1);
                  }}
                  role="button"
                >
                  <i className="tim-icons icon-minimal-left" />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    this.next(1, items1);
                  }}
                  role="button"
                >
                  <i className="tim-icons icon-minimal-right" />
                  <span className="sr-only">Next</span>
                </a>
              </Carousel>
            </Container>
             <Container>
              <Row>
              <br/> <br/> <br/> <br/><br/> <br/> <br/> <br/><br/> <br/> <br/> <br/><br/> <br/> <br/> <br/><br/>
              <br/> <br/> <br/> <br/><br/> 
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonials;
