/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

class WelcomeBlogs extends React.Component {
  render() {
    return (
      <>
        <div className="section section-cards">
          <div className="cards">
          <img alt="..." className="path" src={require("assets/img/dots.png")} />
          <img alt="..." className="path" src={require("assets/img/patrat.png")} />
            <Container>
              <Row>
                <Col lg="4" md="6">
                  <Card className="card-blog">
                      <div className="card-image">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img rounded"
                            src={require("assets/img/Service1.jpg")}
                          />
                        </a>
                      </div>
                      <CardBody className="text-center">
                        <h6 className="category text-danger">
                          <i className="tim-icons icon-sound-wave mr-1" />
                          Service
                        </h6>
                        <CardTitle tag="h5">
                          Our Service
                        </CardTitle>
                        <p className="card-description">
                          SETS can Supply and installation of Aluminum fixtures comprising Doors, 
                          Windows, Skylight-Dome, Handrail, Shutters, Curtain wall <br/>
                          etc.
                        </p>
                        <Button
                className="btn-round"
                color="primary"
                to = "Service-page"
                tag = {Link}
                size="lg"
                
              >
               Explore Our Service
              </Button>
                      </CardBody>
                    </Card>
                </Col>
                <Col lg="4" md="6">
                 <Card className="card-blog">
                    <div className="card-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require("assets/img/Latestwork2.jpg")}
                        />
                      </a>
                    </div>
                    <CardBody className="text-center">
                      <h6 className="category text-danger">
                        <i className="tim-icons icon-sound-wave mr-1" />
                        Latest Work
                      </h6>
                      <CardTitle tag="h5">
                        Latest Work
                      </CardTitle>
                      <p className="card-description">
                       We specialize in offering quality works, which meets 
                       the requirements of our clients accordingly. Some of 
                       our sites include Embassy of Malawi, sholla Apartments.
                      </p>
                      
                      <Button
                          className="btn-round"
                          color="primary"
                          to = "latestWork-page"
                          tag = {Link}
                          size="lg"
                        >
                          Explore our Latest Work
                        </Button>
                       
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" md="8">
                 <Card className="card-blog">
                    <div className="card-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require("assets/img/Set3.jpg")}
                        />
                      </a>
                    </div>
                    <CardBody className="text-center">
                      <h6 className="category text-danger">
                        <i className="tim-icons icon-sound-wave mr-1" />
                        Resources
                      </h6>
                      <CardTitle tag="h5">
                        Sisters Company
                      </CardTitle>
                      <p className="card-description">
                        We also working on other business commercial private limited 
                        liability company with operations and <br/>
                        investments in Ethiopia. 
                      </p>
                      <Button
                          className="btn-round"
                          color="primary"
                          to = "Business-page"
                          tag = {Link}
                          size="lg"
                        >
                          Explore Our Sisters Company
                        </Button>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>{" "}
      </>
    );
  }
}

export default WelcomeBlogs;
